<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <h1>
          {{ $t("notAvailable.title") }}
        </h1>
        <h4>
          {{ $t("notAvailable.subtitle") }}
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotAvailable"
};
</script>
